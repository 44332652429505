(function ($, site) {
  Drupal.behaviors.productGridProducts = {
    attach: function (context) {
      $('.js-product-grid-products--carousel', context).each(function () {
        site.productCarousel.init($(this));
      });

      // this will be executed only when sku card is enabled in CMS
      var $container = $('.js-sku-card', context);

      if ($container.length > 0) {
        $container.each(function () {
          var $this = $(this);
          var shade = $this.data('shade-name');

          // removing shade numbers (skin foundation shades)
          shade = shade.replace(/[0-9.()\ ]+$/g, '');
          var shadeName = shade.split(' ').join('_');
          var $anchorLink = $this.find('.js-sku-image-link');

          $anchorLink.each(function () {
            var $thisLink = $(this);
            var oldLink = $thisLink.attr('href');
            var newLink = oldLink + '#/shade/' + shadeName;

            $thisLink.attr('href', newLink);
          });
        });
      }
    }
  };
})(jQuery, site || {});
